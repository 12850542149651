// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --floating-actions-bar-height: 85px;
  --floating-actions-bar-padding: calc((var(--floating-actions-bar-height) - 35px) / 2);
}

.floating-actions-bar {
  position: fixed;
  display: flex;
  right: var(--floating-actions-bar-padding);
  bottom: var(--floating-actions-bar-padding);
  z-index: var(--elevation-drawer);
}

.floating-actions-bar > * {
    margin-left: 6px;
  }

.floating-actions-bar > *:first-child {
      margin-left: 0;
    }

.floating-actions-bar-buffer {
  position: relative;
  width: 100%;
  height: var(--floating-actions-bar-height);
  min-height: var(--floating-actions-bar-height);
}
`, "",{"version":3,"sources":["webpack://./app/components/FloatingActionsBar/style.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,qFAAqF;AACvF;;AAEA;EACE,eAAe;EACf,aAAa;EACb,0CAA0C;EAC1C,2CAA2C;EAC3C,gCAAgC;AASlC;;AAPE;IACE,gBAAgB;EAKlB;;AAHE;MACE,cAAc;IAChB;;AAIJ;EACE,kBAAkB;EAClB,WAAW;EACX,0CAA0C;EAC1C,8CAA8C;AAChD","sourcesContent":[":root {\n  --floating-actions-bar-height: 85px;\n  --floating-actions-bar-padding: calc((var(--floating-actions-bar-height) - 35px) / 2);\n}\n\n.floating-actions-bar {\n  position: fixed;\n  display: flex;\n  right: var(--floating-actions-bar-padding);\n  bottom: var(--floating-actions-bar-padding);\n  z-index: var(--elevation-drawer);\n\n  & > * {\n    margin-left: 6px;\n\n    &:first-child {\n      margin-left: 0;\n    }\n  }\n}\n\n.floating-actions-bar-buffer {\n  position: relative;\n  width: 100%;\n  height: var(--floating-actions-bar-height);\n  min-height: var(--floating-actions-bar-height);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
