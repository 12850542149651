// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-file-select-zone {
  position: relative;
}

  .r-file-select-zone--disabled {
    pointer-events: none;
    user-select: none;
  }

  .r-file-select-zone__file-upload {
    position: relative;
    display: flex;
    border: dashed 1px var(--color-grayscale-4);
    height: 33px;
    align-items: center;
    padding: 12px;
    outline: none;
    cursor: pointer;
    background-color: #f7f8f9;
  }

  .r-file-select-zone__file-upload--accepted {
      background: green;
    }

  .r-file-select-zone__file-upload--rejected {
      background: red;
    }

  .r-file-select-zone__file-upload--disabled {
      color: var(--color-grayscale-5);
      background: var(--color-grayscale-2);
    }

  .r-file-select-zone__file-upload--disabled * {
        color: var(--color-grayscale-5) !important;
      }

  .r-file-select-zone__file-upload-text {
      margin-left: 4px;
    }

  .r-file-select-zone__file-upload-text:first-child {
        margin-left: 0;
      }

  .r-file-select-zone__file-upload-text-link {
        color: #007aff;
      }

  .r-file-select-zone__file-upload-icon {
      height: 14px;
      margin-right: 3px;
    }

  .r-file-select-zone__file-upload-spinner {
      position: absolute;
      right: 8px;
      height: 14px;
      margin-right: 3px;
    }

  .r-file-select-zone__file-upload-close-btn {
      position: absolute;
      right: 0;
      height: 14px;
      padding: 0;
      min-width: 34px;
    }
`, "",{"version":3,"sources":["webpack://./app/components/FileSelectZone/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AAmEpB;;EAjEE;IACE,oBAAoB;IACpB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,2CAA2C;IAC3C,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,eAAe;IACf,yBAAyB;EAkD3B;;EAhDE;MACE,iBAAiB;IACnB;;EAEA;MACE,eAAe;IACjB;;EAEA;MACE,+BAA+B;MAC/B,oCAAoC;IAKtC;;EAHE;QACE,0CAA0C;MAC5C;;EAGF;MACE,gBAAgB;IASlB;;EAPE;QACE,cAAc;MAChB;;EAEA;QACE,cAAc;MAChB;;EAGF;MACE,YAAY;MACZ,iBAAiB;IACnB;;EAEA;MACE,kBAAkB;MAClB,UAAU;MACV,YAAY;MACZ,iBAAiB;IACnB;;EAEA;MACE,kBAAkB;MAClB,QAAQ;MACR,YAAY;MACZ,UAAU;MACV,eAAe;IACjB","sourcesContent":[".r-file-select-zone {\n  position: relative;\n\n  &--disabled {\n    pointer-events: none;\n    user-select: none;\n  }\n\n  &__file-upload {\n    position: relative;\n    display: flex;\n    border: dashed 1px var(--color-grayscale-4);\n    height: 33px;\n    align-items: center;\n    padding: 12px;\n    outline: none;\n    cursor: pointer;\n    background-color: #f7f8f9;\n\n    &--accepted {\n      background: green;\n    }\n\n    &--rejected {\n      background: red;\n    }\n\n    &--disabled {\n      color: var(--color-grayscale-5);\n      background: var(--color-grayscale-2);\n\n      & * {\n        color: var(--color-grayscale-5) !important;\n      }\n    }\n\n    &-text {\n      margin-left: 4px;\n\n      &:first-child {\n        margin-left: 0;\n      }\n\n      &-link {\n        color: #007aff;\n      }\n    }\n\n    &-icon {\n      height: 14px;\n      margin-right: 3px;\n    }\n\n    &-spinner {\n      position: absolute;\n      right: 8px;\n      height: 14px;\n      margin-right: 3px;\n    }\n\n    &-close-btn {\n      position: absolute;\n      right: 0;\n      height: 14px;\n      padding: 0;\n      min-width: 34px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
