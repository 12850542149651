// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown {
  position: relative;
  height: 32px;
}

  .Dropdown__btn {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
  }
`, "",{"version":3,"sources":["webpack://./app/components/DropdownButton/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AAQd;;EANE;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,YAAY;EACd","sourcesContent":[".Dropdown {\n  position: relative;\n  height: 32px;\n\n  &__btn {\n    min-width: 100%;\n    min-height: 100%;\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
