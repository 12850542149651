/* eslint-disable max-classes-per-file */
import React from 'react';
import ReactOutsideEvent from 'react-outside-event';
import moment from 'moment-timezone';
import { TIME_ZONES } from '@drayalliance/types';
import ExclamationCircleIcon from '@components/deprecatedTookit/icons/fa/regular/ExclamationCircleIcon';
import copy from 'clipboard-copy';
import debounce from 'lodash/debounce';
import DropdownSelectInput from '../DropdownSelectInput';
import TextInput from '../TextInput';
import DateTimePicker from '../DateTimePicker';
import DateTimeRangePicker from '../DateTimeRangePicker';
import { TOAST_POSITION, raiseToast } from '../Toaster/component';
import SystemToast from '../SystemToast/component';
import CopyButton from '../CopyButton';

import './style.css';

const EditingFieldHOC = ReactOutsideEvent(
  class EditingField extends React.Component<{
    children: any;
    // eslint-disable-next-line no-unused-vars
    onOutsideEvent: (...args: any) => void;
  }> {
    onOutsideEvent = (event: any) => {
      setTimeout(() => {
        this.props.onOutsideEvent(event);
      }, 33);
    };

    render() {
      return this.props.children;
    }
  }
);

interface Props {
  /**
   * Required
   */
  className: string;
  type: 'text' | 'textarea' | 'date' | 'date-time' | 'date-time-range' | 'dropdown';
  value: string | number | Record<string, any> | null | boolean;
  editable: boolean;
  dataResourceType: string;
  dataId: string;
  dataField: string;
  dataFieldType: string;
  width: any;

  /**
   * Optional
   */
  suppressEdit?: boolean;
  noUnderline?: boolean;
  error?: boolean;
  disabled?: boolean;
  alwaysSave?: boolean;
  alwaysUpdate?: boolean;
  importantText?: string;
  options?: Record<string, any>;
  inputProps?: Record<string, any>;
  placeholder?: string;
  // eslint-disable-next-line no-unused-vars
  displayFormatter?: (...args: any) => any;
  // eslint-disable-next-line no-unused-vars
  inputValueFormatter?: (...args: any) => any;
  // eslint-disable-next-line no-unused-vars
  outputValueFormatter?: (...args: any) => any;
  // eslint-disable-next-line no-unused-vars
  onSave?: (...args: any) => any;
  allowNull?: boolean;
  defaultSelected?: boolean;
  // eslint-disable-next-line no-unused-vars
  isValidDate?: (...args: any) => any;
  rightComponents?: any;
  // eslint-disable-next-line no-unused-vars
  onChange?: (...args: any) => any;
  // eslint-disable-next-line no-unused-vars
  onClick?: (...args: any) => any;
  valueFields?: any[];
  // eslint-disable-next-line no-unused-vars
  onCopy?: (...args: any) => any;
  // eslint-disable-next-line no-unused-vars
  onClose?: (...args: any) => any;
  copyable?: boolean;
  icon?: any;
  label?: string;
  timezone?: string;
}

class EditableField extends React.PureComponent<Props> {
  inputEl?: any = undefined;

  // eslint-disable-next-line react/state-in-constructor
  state: any = {
    editable: this.props.editable,
    editing: this.props.defaultSelected || false,
    value: this.props.value || this.props.value === 0 || (this.props as any).value === false ? this.props.value : ''
  };

  componentDidMount() {
    if (this.props.defaultSelected && this.inputEl && this.state.editing) {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.inputEl?.select();
        // eslint-disable-next-line no-unused-expressions
        this.inputEl?.focus();
      }, 250);
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: any) {
    if (nextProps.alwaysUpdate || nextProps.value !== this.props.value) {
      if (this.props.type === 'date-time-range') {
        if (
          nextProps.value &&
          nextProps.value.startValue !== (this.props.value as Record<string, any>).startValue &&
          nextProps.value.endValue !== (this.props.value as Record<string, any>).endValue
        ) {
          this.state.value = nextProps.value;
        }
      } else {
        this.state.value = nextProps.value;
      }
    }

    this.state.editable = nextProps.editable;
  }

  handleChange = (value: any, props: Props) => {
    if (this.props.onChange) {
      this.props.onChange(value, props);
    }
  };

  handleInputRef = (el: any) => {
    if (el) {
      this.inputEl = el;
    }
  };

  handleTextInputChange = (value: any) => {
    if (value) value = value.trim();

    this.setState(() => ({
      value
    }));

    if (this.handleChange) {
      this.handleChange(value, this.props);
    }
  };

  handleTextAreaInputChange = (event: any) => {
    const { value } = event.target;

    this.setState(() => ({
      value
    }));

    if (this.handleChange) {
      this.handleChange(value, this.props);
    }
  };

  handleDropdownInputChange = (values: any, { valueField, clearable }: any) => {
    let value = null;

    if (values[0]) {
      value = valueField ? values[0][valueField] : values[0].value;
    }

    if (!value && value !== false) {
      value = '';
    }

    if (clearable === false && !value) {
      return;
    }

    if (value !== this.state.value) {
      const v = value === '' ? null : value;

      this.setState(() => ({
        value: v
      }));

      if (this.handleChange) {
        this.handleChange(v, this.props);
      }
    }
  };

  // handletInputChange = (event: any) => {
  //   const value = this.processOutputValue(event.target.value);

  //   this.setState((prevState, currProps) => {
  //     if (currProps.onChange) {
  //       currProps.onChange(value, currProps);
  //     }

  //     return {
  //       value
  //     };
  //   });
  // };

  handleDatePickerChange = (value: any) => {
    let momentValue: moment.Moment;
    if (this.props.timezone) {
      momentValue = moment(value).tz(this.props.timezone);
    } else {
      momentValue = moment(value);
    }

    let formattedValue = null;
    let outputValue: any = null;
    let inputValue: any = null;

    if (value && momentValue.isValid()) {
      formattedValue = momentValue.utc().format('YYYY-MM-DD');
      outputValue = this.processOutputValue(formattedValue);
      inputValue = this.processInputValue(formattedValue);
    }

    this.setState((prevState, currProps) => {
      if (currProps.onChange) {
        currProps.onChange(outputValue, currProps);
      }

      return {
        value: inputValue
      };
    });
  };

  handleDateTimePickerChange = (value: any) => {
    let momentValue: moment.Moment;
    if (this.props.timezone) {
      momentValue = moment(value).tz(this.props.timezone);
    } else {
      momentValue = moment(value);
    }

    let formattedValue = null;
    let outputValue: any = null;
    let inputValue: any = null;

    if (value && momentValue.isValid()) {
      formattedValue = momentValue.format();
      outputValue = this.processOutputValue(formattedValue);
      inputValue = this.processInputValue(formattedValue);
    }

    this.setState((prevState, currProps) => {
      if (currProps.onChange) {
        currProps.onChange(outputValue, currProps);
      }

      return {
        value: inputValue
      };
    });
  };

  handleDateTimeRangePickerChange = (startValue: any, endValue: any) => {
    let startMoment: moment.Moment;
    let endMoment: moment.Moment;
    if (this.props.timezone) {
      startMoment = moment(startValue).tz(this.props.timezone);
      endMoment = moment(endValue).tz(this.props.timezone);
    } else {
      startMoment = moment(startValue);
      endMoment = moment(endValue);
    }

    let formattedStartValueValue: any = null;
    let formattedEndValueValue: any = null;

    if (startValue && startMoment.isValid()) {
      formattedStartValueValue = startMoment.format();
    }

    if (endValue && endMoment.isValid()) {
      formattedEndValueValue = endMoment.format();
    }

    const stringValuesUTC = this.processOutputValue({
      startValue: formattedStartValueValue,
      endValue: formattedEndValueValue
    });

    this.setState(
      () => ({
        value: {
          startValue: formattedStartValueValue,
          endValue: formattedEndValueValue
        }
      }),
      () => {
        if (this.handleChange) {
          (this.props.valueFields || []).forEach((item) => {
            this.handleChange(stringValuesUTC[item.valueField], item);
          });
        }
      }
    );
  };

  handleClick = () => {
    if (!this.props.suppressEdit) {
      this.setState(
        () => ({
          editing: true
        }),
        () => {
          if (this.inputEl) {
            this.inputEl.focus();
            this.inputEl.select();
          }
        }
      );
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  handleCopy = (e: any) => {
    e.stopPropagation();
    if (this.props.onCopy) {
      this.props.onCopy(this.state.value, copy);
    } else {
      copy(this.state.value);
      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Copied to clipboard" />, {
        position: TOAST_POSITION.BOTTOM_LEFT
      });
    }
  };

  handleOutsideEvent = (event: any) => {
    this.setState((prevState: any, currProps: Props) => {
      let value = this.processInputValue(prevState.value);
      let currValue = currProps.value;
      if (!currValue && currValue !== 0) {
        currValue = null;
      }

      if (!value) {
        value = null;
      }

      if ((this.props.alwaysSave || currValue !== value) && currProps.onSave) {
        currProps.onSave(value, currProps, event);
      }

      if (this.props.onClose) {
        this.props.onClose();
      }

      return {
        editing: false,
        value
      };
    });
  };

  processOutputValue = (value: any): any =>
    value && this.props.outputValueFormatter ? this.props.outputValueFormatter(value) : value;

  processInputValue = (value: any): any =>
    value && this.props.inputValueFormatter ? this.props.inputValueFormatter(value) : value;

  render() {
    const baseClassName = 'r-editable-field';
    let className = baseClassName;
    const baseClassNameText = 'r-editable-field__text';
    let classNameText = baseClassNameText;
    const classNamePlaceholderText = 'r-editable-field__placeholder';
    let component;
    let displayValue = this.state.value;
    const inputProps = this.props.inputProps || {};
    let showImportantText = false;

    if (
      this.props.type === 'date-time-range' &&
      this.props.importantText &&
      !this.state.value.startValue &&
      !this.state.value.endValue
    ) {
      showImportantText = true;
    } else if (this.props.importantText && !this.state.value) {
      showImportantText = true;
    }

    if (showImportantText) {
      classNameText += '-important';

      displayValue = (
        <>
          {this.props.importantText}
          <ExclamationCircleIcon />
        </>
      );
    } else if (!this.state.editing && this.props.displayFormatter && this.state.value) {
      displayValue = this.props.displayFormatter(this.state.value);
    } else if (
      !this.state.editing &&
      this.props.displayFormatter &&
      this.props.value === null &&
      this.props.allowNull
    ) {
      displayValue = this.props.displayFormatter(null);
    } else if (
      !this.state.editing &&
      this.props.displayFormatter &&
      (this.props.value === false || this.state.value === false)
    ) {
      displayValue = this.props.displayFormatter(false);
    }

    if (this.props.className) {
      className += ` ${this.props.className}`;
    }

    if (this.props.noUnderline) {
      className += ` ${baseClassName}--no-underline`;
    }

    if (this.state.editing) {
      switch (this.props.type) {
        case 'dropdown':
          // eslint-disable-next-line no-case-declarations
          const dropdownValues = [];

          if (inputProps.options) {
            const optionValueFieldName = inputProps.valueField || 'value';

            dropdownValues[0] =
              inputProps.options.find((item: any) => item[optionValueFieldName] === this.state.value) || {};
          } else {
            dropdownValues[0] = {};
          }

          component = (
            <EditingFieldHOC onOutsideEvent={this.handleOutsideEvent}>
              <DropdownSelectInput
                className="r-editable-field__input"
                autoFocus
                autoSelect
                dropdownHandle={false}
                keepOpen
                values={dropdownValues}
                {...(this.props.value ? { value: this.props.value } : {})}
                placeholder={this.props.placeholder}
                {...inputProps}
                onChange={this.handleDropdownInputChange}
              />
            </EditingFieldHOC>
          );
          break;
        case 'date':
          component = (
            <EditingFieldHOC onOutsideEvent={this.handleOutsideEvent}>
              <DateTimePicker
                className="r-editable-field__date-time-picker"
                timeFormat={false}
                value={this.processInputValue(this.state.value ? moment(this.state.value).format('MM/DD/YYYY') : null)}
                onChange={this.handleDatePickerChange}
                timezone={this.props.timezone || TIME_ZONES.AMERICA_LOS_ANGELES}
              />
            </EditingFieldHOC>
          );
          break;
        case 'date-time':
          component = (
            <EditingFieldHOC onOutsideEvent={debounce((e) => this.handleOutsideEvent(e), 10)}>
              <DateTimePicker
                className="r-editable-field__date-time-picker"
                value={this.processInputValue(this.state.value)}
                onChange={this.handleDateTimePickerChange}
                isValidDate={this.props.isValidDate}
                timezone={this.props.timezone || TIME_ZONES.AMERICA_LOS_ANGELES}
              />
            </EditingFieldHOC>
          );
          break;
        case 'date-time-range':
          component = (
            <EditingFieldHOC onOutsideEvent={debounce((e) => this.handleOutsideEvent(e), 10)}>
              <DateTimeRangePicker
                className="r-editable-field"
                {...inputProps}
                startValue={this.processInputValue(this.state.value ? this.state.value.startValue : null)}
                endValue={this.processInputValue(this.state.value ? this.state.value.endValue : null)}
                onChange={this.handleDateTimeRangePickerChange}
                timezone={this.props.timezone || TIME_ZONES.AMERICA_LOS_ANGELES}
              />
            </EditingFieldHOC>
          );
          break;
        case 'textarea':
          className += ' r-editable-field__textarea';

          component = (
            <EditingFieldHOC onOutsideEvent={this.handleOutsideEvent}>
              <textarea
                className="r-editable-field__textarea-input"
                ref={this.handleInputRef}
                value={this.state.value}
                onChange={this.handleTextAreaInputChange}
                aria-label="Text area"
              />
            </EditingFieldHOC>
          );
          break;
        default:
          component = (
            <EditingFieldHOC onOutsideEvent={this.handleOutsideEvent}>
              <TextInput
                className="r-editable-field__input"
                autoFocus
                autoSelect
                value={this.state.value}
                placeholder={this.props.placeholder}
                {...inputProps}
                onChange={this.handleTextInputChange}
                onRef={this.handleInputRef}
              />
            </EditingFieldHOC>
          );
      }
    } else {
      switch (this.props.type) {
        case 'dropdown':
          break;
        case 'date':
          break;
        case 'date-time':
          break;
        case 'date-time-range':
          break;
        case 'textarea':
          className += ' r-editable-field__textarea';
          classNameText += ' r-editable-field__textarea-display';
          break;
        default:
      }

      if (!this.state.editing && this.state.editable) {
        className += ' r-editable-field__text--editable';
      }

      if (this.props.disabled) {
        className += ' r-editable-field--disabled';
      }

      if (this.props.error) {
        classNameText += ' r-editable-field__text--error';
      }

      component = (
        <div className={classNameText} {...inputProps}>
          {displayValue || displayValue === 0 ? (
            displayValue
          ) : (
            <span className={classNamePlaceholderText}>{this.props.placeholder}</span>
          )}
        </div>
      );
    }

    let copyButtonClassName = 'r-editable-field-copy-icon-wrapper';
    if (!this.state.value) {
      copyButtonClassName += ' r-editable-field-copy-icon-wrapper--disabled';
    }

    return (
      <div className={className} onClick={this.state.editing || !this.props.editable ? undefined : this.handleClick}>
        {this.props.label && <p className="r-editable-field-label">{this.props.label}</p>}
        {this.props.icon && this.props.icon}
        <div
          className={`r-editable-field-content-wrapper ${
            this.props.rightComponents ? 'r-editable-field__right-components-content-wrap' : ''
          }`}
        >
          {component}
        </div>
        {this.props.copyable && (
          <CopyButton value={this.state.value} className={copyButtonClassName} onClick={this.handleCopy} />
        )}
        {this.props.rightComponents && (
          <div className="r-editable-field__right-components">{this.props.rightComponents}</div>
        )}
      </div>
    );
  }
}

export default React.memo(EditableField);
