import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Component from './component';
import { appErrorStateContext, appErrorActionContext } from '../App/contexts';
import { createErrorFieldId } from '../../state/utils';
import { removeNotification } from '../../state/notifications/actions';

export default function Consumer(props) {
  const errorState = useContext(appErrorStateContext);
  const errorActionDispatch = useContext(appErrorActionContext);
  const { errorResourceIds } = errorState;
  let hasError = false;
  const id = createErrorFieldId(
    props.dataId || props.id,
    props.dataResourceType || props.resourceType,
    props.dataField || props.name
  );
  const inputProps = props.inputProps || {};

  inputProps.id = id;

  if (errorResourceIds) {
    hasError = !!errorResourceIds[id];
  }

  const handleChange = (value, cProps) => {
    if (props.onChange) {
      props.onChange(value, cProps);
    }

    if (hasError) {
      errorActionDispatch(removeNotification(id));
    }
  };

  return <Component {...props} error={hasError} inputProps={inputProps} onChange={handleChange} />;
}

Consumer.propTypes = {
  dataField: PropTypes.string,
  dataId: PropTypes.string,
  dataResourceType: PropTypes.string,
  id: PropTypes.any,
  inputProps: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  resourceType: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  editable: PropTypes.bool,
  value: PropTypes.any,
  onSave: PropTypes.func,
  displayFormatter: PropTypes.func,
  timezone: PropTypes.string,
  showTimezones: PropTypes.bool,
  placeholder: PropTypes.string,
  searchBy: PropTypes.string,
  copyable: PropTypes.bool,
  onCopy: PropTypes.func,
  onBlur: PropTypes.func,
  valueFields: PropTypes.array,
  suppressEdit: PropTypes.bool,
  onClick: PropTypes.any,
  alwaysUpdate: PropTypes.bool,
  disabled: PropTypes.bool,
  rightComponents: PropTypes.any,
  startValue: PropTypes.any,
  endValue: PropTypes.any,
  alwaysSave: PropTypes.bool,
  noUnderline: PropTypes.bool,
  allowNull: PropTypes.bool
};
