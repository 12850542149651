import React, { useState, useRef, useEffect } from 'react';
import EllipsisVIcon from '@components/deprecatedTookit/icons/fa/regular/EllipsisVIcon';
import Flyout from '../Flyout';
import IconButton from '../IconButton';

import './style.css';

interface DropdownButtonProps {
  className: string;
  children: any;
  buttonProps: any;
  flyoutProps: any;
  onMenuClosed?: any;
  onMenuOpened?: any;
}

function DropdownButton(props: DropdownButtonProps) {
  const [state, setState] = useState({ open: false });
  const node = useRef();
  const baseClassName = 'Dropdown';
  let className = baseClassName;

  if (props.className) {
    className += ` ${props.className}`;
  }

  const handleBtnClick = () => {
    setState((prevState) => ({
        ...prevState,
        open: !prevState.open
      }));
  };

  const handleCloseMenu = () => {
    setState((prevState) => ({
        ...prevState,
        open: false
      }));

    if (props.onMenuClosed) {
      props.onMenuClosed();
    }
  };

  const handleMenuOpen = () => {
    if (props.onMenuOpened) {
      props.onMenuOpened();
    }
  };

  const handleDocClick = (event: any) => {
    // @ts-ignore
    if (node.current.contains(event.target)) {
      // inside click
      return;
    }

    handleCloseMenu();
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleDocClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleDocClick);
    };
  }, []);

  return (
    // @ts-ignore
    <div ref={node} className={className}>
      <IconButton
        className={`${baseClassName}__btn`}
        icon={<EllipsisVIcon />}
        onClick={handleBtnClick}
        {...props.buttonProps}
      />
      {state.open && (
        <Flyout onClose={handleCloseMenu} onOpen={handleMenuOpen} {...props.flyoutProps}>
          {props.children}
        </Flyout>
      )}
    </div>
  );
}

export default DropdownButton;
