import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import CaretDownIcon from '@components/deprecatedTookit/icons/fa/solid/CaretDownIcon';
import TimesIcon from '@components/deprecatedTookit/icons/fa/regular/TimesIcon';
import LoadingScreen from '@components/deprecatedTookit/LoadingScreen';
import Select from 'react-dropdown-select';
import TextInput from '../TextInput';
import Button from '../Button';

import './style.css';

const defaultLabelField = 'label';

function loadingDropdownRenderer() {
  return <LoadingScreen />;
}

function noContentRenderer(contentRendererArgs) {
  const handleChange = useDebouncedCallback(
    (val) => {
      contentRendererArgs.methods.setSearch({ target: { value: val } });
    },
    // delay in ms
    400
  );

  return (
    <TextInput
      className="app-dropdown-select-input__text-input"
      tabIndex={contentRendererArgs.props.tabIndex}
      autoFocus={!!contentRendererArgs.props.autoFocus}
      autoSelect={!!contentRendererArgs.props.autoSelect}
      placeholder={contentRendererArgs.props.placeholder}
      value={contentRendererArgs.state.search}
      onChange={handleChange}
    />
  );
}

function singleContentRenderer(contentRendererArgs) {
  const handleChange = useDebouncedCallback(
    (val) => {
      // contentRendererArgs.methods.setSearch({ target: { value: val } });
      let result = null;
      const searchValue = val.toLowerCase();

      if (val) {
        result = contentRendererArgs.props.baseOptions.filter(
          (opt) =>
            opt[contentRendererArgs.props.labelField || defaultLabelField].toLowerCase().indexOf(searchValue) >= 0
        );
      } else {
        result = contentRendererArgs.props.baseOptions.slice(
          0,
          contentRendererArgs.props.maxListLen || contentRendererArgs.props.baseOptions.length
        );
      }

      contentRendererArgs.props.setState((ps) => ({
        ...ps,
        searchResults: result || []
      }));

      if (contentRendererArgs.props.onTextChange) {
        contentRendererArgs.props.onTextChange(val);
      }
    },
    // delay in ms
    400
  );
  const value = contentRendererArgs.state.values[0] || {};
  const { displayFormatter } = contentRendererArgs.props;
  const labelField = contentRendererArgs.props.labelField || defaultLabelField;
  let initialValue = contentRendererArgs.state.values.length > 0 && value[labelField];

  if (!initialValue) {
    initialValue = '';
  }

  return (
    <TextInput
      className="app-dropdown-select-input__text-input"
      tabIndex={contentRendererArgs.props.tabIndex}
      autoFocus={!!contentRendererArgs.props.autoFocus}
      autoSelect={!!contentRendererArgs.props.autoSelect}
      placeholder={contentRendererArgs.props.placeholder}
      value={displayFormatter ? displayFormatter(value) : initialValue}
      onChange={handleChange}
    />
  );
}

function dropdownHandleRenderer(args) {
  return (
    <CaretDownIcon
      onClick={() => {
        args.methods.dropDown('toggle');
      }}
    />
  );
}

export default function DropdownSelectInput(props) {
  const [state, setState] = useState({
    options: props.options,
    prevSearchVal: null,
    searchResults: props.options ? props.options.slice(0, props.maxListLen || props.options.length) : []
  });
  const [stateSelected, setStateSelected] = useState(false);
  const [asyncLoading, setAsyncLoading] = useState(!!props.onLoadOptions);
  const prevValues = useRef(props.values || []);
  const prevOptions = useRef(props.options);

  if (props.options && prevOptions.current !== props.options) {
    prevOptions.current = props.options;
    state.options = props.options;
    state.searchResults = props.options ? props.options.slice(0, props.maxListLen || props.options.length) : [];
  }

  if (!props.options && prevOptions.current !== state.options) {
    prevOptions.current = state.options;
    state.searchResults = state.options ? state.options.slice(0, props.maxListLen || state.options.length) : [];
  }

  const handleChange = (values) => {
    const valueField = props.valueField || 'value';
    if (
      props.onChange && props.multi
        ? prevValues.current.length !== values.length
        : prevValues.current[0]?.[valueField] !== values[0]?.[valueField]
    ) {
      prevValues.current = values;
      props.onChange(values, props);
    }
  };

  const handleAsyncClick = async () => {
    if (props.onLoadOptions) {
      try {
        setAsyncLoading(() => true);

        const options = await props.onLoadOptions();

        setState((prevState) => ({
          ...prevState,
          options
        }));
      } finally {
        setAsyncLoading(() => false);

        setStateSelected(() => true);

        if (props.onDropdownOpen) {
          props.onDropdownOpen();
        }
      }
    }
  };

  const handleDropdownOpen = () => {
    if (props.onLoadOptions) {
      handleAsyncClick();

      setStateSelected(() => true);

      if (props.onDropdownOpen) {
        props.onDropdownOpen();
      }
    } else {
      setStateSelected(() => true);

      if (props.onDropdownOpen) {
        props.onDropdownOpen();
      }
    }
  };

  const handleDropdownClose = () => {
    setStateSelected(() => false);

    if (props.onLoadOptions) {
      setState((prevState) => ({
        ...prevState,
        options: []
      }));
    }

    if (props.onDropdownClose) {
      props.onDropdownClose();
    }
  };

  let contentRenderer = null;
  let dropdownRenderer = null;

  if (props.onLoadOptions) {
    dropdownRenderer = loadingDropdownRenderer;
  }

  if (props.noContentUpdate) {
    contentRenderer = noContentRenderer;
  } else if (!props.multi) {
    contentRenderer = singleContentRenderer;
  }

  const baseClassName = 'app-dropdown-select-input';
  let className = baseClassName;

  if (stateSelected) {
    className += ' app-dropdown-select-input--selected';
  }

  if (props.className) {
    className += ` ${props.className}`;
  }

  const { clearable, ...selectProps } = props;

  return (
    <>
      {props.label && (
        <label htmlFor={props.name} className="r-text-input-label">
          {props.label}
        </label>
      )}

      <Select
        color="var(--color-green-8)"
        dropdownGap={0}
        dropdownPosition="auto"
        dropdownRenderer={asyncLoading ? dropdownRenderer : null}
        dropdownHandleRenderer={dropdownHandleRenderer}
        contentRenderer={contentRenderer}
        {...selectProps}
        setState={setState}
        options={state.searchResults}
        baseOptions={state.options}
        maxListLen={props.maxListLen}
        values={props.values}
        clearOnBlur
        onDropdownOpen={handleDropdownOpen}
        onDropdownClose={handleDropdownClose}
        onChange={handleChange}
        onTextChange={props.onTextChange}
        className={className}
        searchBy={props.searchBy}
        // eslint-disable-next-line react/no-unstable-nested-components
        clearRenderer={({ methods }) => (
          <Button
            className="app-dropdown-select-input__clear-btn"
            icon={<TimesIcon />}
            variant="text"
            onClick={(event) => {
              event.stopPropagation();

              methods.clearAll();
            }}
          />
        )}
      />
    </>
  );
}

DropdownSelectInput.propTypes = {
  autoSelect: PropTypes.bool,
  noContentUpdate: PropTypes.bool,
  onDropdownOpen: PropTypes.func,
  onDropdownClose: PropTypes.func,
  onChange: PropTypes.func,
  onLoadOptions: PropTypes.func,
  onTextChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  values: PropTypes.array,
  options: PropTypes.array,
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  groupName: PropTypes.string,
  displayFormatter: PropTypes.func,
  multi: PropTypes.bool,
  maxListLen: PropTypes.number,
  itemRenderer: PropTypes.any,
  searchBy: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownHandle: PropTypes.bool,
  clearable: PropTypes.bool,
  dataGroup: PropTypes.string,
  dataField: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  autoFocus: PropTypes.bool,
  keepOpen: PropTypes.bool,
  resourceType: PropTypes.string,
  dataResourceType: PropTypes.string,
  dataFieldType: PropTypes.string,
  dataId: PropTypes.string,
  additionalProps: PropTypes.any,
  label: PropTypes.any,
  dropdownHandleRenderer: PropTypes.any
};
