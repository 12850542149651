import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DotCircleIcon from '@components/deprecatedTookit/icons/fa/regular/DotCircleIcon';
import { selectHasTerminalAPIUpdates } from '@client/_blessed/store/entities';
import { Env, getAppUrl } from '@drayalliance/utils';
import { capitalize } from 'lodash';
import history from '../../utils/history';
import ProfileInfo from '../ProfileInfo';
import CompanyLogo from '../CompanyLogo';
import { canIUse } from '../../utils/can-i-use';
import AccountDropdown from '../AccountDropdown';

import './style.css';
import { getEnvModifier } from './utils';

const isOnLiveMap = (path) => path.split('/')[3] === 'live-map';

export default function Header(props) {
  const { centerContent, rightContent, leftContent } = props;
  const [isOpen, setIsOpen] = useState(false);

  const hasTerminalAPIUpdates = useSelector(selectHasTerminalAPIUpdates);

  const toggleOpen = () => {
    setIsOpen((value) => !value);
  };

  const navPath = history.location.pathname.split('/').slice(0, 4).join('/');

  const navButtonClassName = (id) => {
    let classname = 'header-nav-btn';

    if (id) {
      const idPath = id.split('/').slice(0, 4).join('/');

      if (idPath === navPath) {
        classname += ' header-nav-btn--selected';
      }
    }

    if (isOnLiveMap(navPath)) {
      classname += ' header-nav-btn-dark';
    }

    return classname;
  };

  const navIds = {
    dispatch: '/hub/dashboard/dispatch',
    liveMap: '/hub/dashboard/live-map',
    createOrders: '/hub/dashboard/create-orders',
    tools: '/hub/dashboard/tools',
    arap: '/hub/dashboard/ar-ap/driver-pay-pending',
    settings: '/hub/dashboard/settings',
    admin: '/hub/dashboard/admin',
    terminalAPI: '/hub/dashboard/terminal-api',
    shipper: `${getAppUrl(window.location.hostname, 'shipper', 9001)}`
  };

  const isOps = canIUse({
    role: props.departmentType,
    whitelistGroups: ['admin']
  });

  const showTerminalAPIIndicator = navPath !== navIds.terminalAPI && hasTerminalAPIUpdates;
  const envModifier = getEnvModifier(window.location.origin);

  return (
    <>
      <div className="header-buffer" />
      <header className={`header ${envModifier.modifier ? `header--${envModifier.modifier}` : ''}`}>
        <div className="header-left">
          <div className="logo-wrapper">
            <CompanyLogo />
          </div>
          {isOps && (
            <Link to={navIds.createOrders} className={navButtonClassName(navIds.createOrders)}>
              Create Orders
            </Link>
          )}
          <Link
            to={
              props.location.pathname === navIds.dispatch
                ? `${navIds.dispatch}${props.location.search}`
                : navIds.dispatch
            }
            className={navButtonClassName(navIds.dispatch)}
          >
            Dispatch
          </Link>
          {isOps && (
            <Link to={navIds.arap} className={navButtonClassName(navIds.arap)}>
              AR/AP
            </Link>
          )}
          {isOps && (
            <Link to={navIds.settings} className={navButtonClassName(navIds.settings)}>
              Settings
            </Link>
          )}
          {isOps && (
            <Link to={navIds.liveMap} className={navButtonClassName(navIds.liveMap)}>
              Live Map
            </Link>
          )}
          {isOps && (
            <Link to={navIds.admin} className={navButtonClassName(navIds.admin)}>
              Admin Tooling
            </Link>
          )}
          {isOps && (
            <Link to={navIds.terminalAPI} className={navButtonClassName(navIds.terminalAPI)}>
              {showTerminalAPIIndicator && <DotCircleIcon className="header-nav-btn-notification-indicator" />}
              Terminal API
            </Link>
          )}
          <a href={navIds.shipper} target="_blank" rel="noreferrer noopener" className={navButtonClassName()}>
            Shipper
          </a>
          {envModifier.env !== Env.Production && envModifier.env && (
            <div className="header__env-text">{capitalize(envModifier.env)} Environment</div>
          )}
          {leftContent}
        </div>
        <div className="header-center">{centerContent}</div>
        <div className="header-right" onClick={toggleOpen}>
          <ProfileInfo />
          <AccountDropdown isOpen={isOpen} requestLogout={props.onLogout} />
          {rightContent}
        </div>
      </header>
    </>
  );
}

Header.propTypes = {
  location: PropTypes.object,
  departmentType: PropTypes.string,
  centerContent: PropTypes.any,
  leftContent: PropTypes.any,
  rightContent: PropTypes.any,
  onLogout: PropTypes.func
};
