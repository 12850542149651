import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import UploadIcon from '@components/deprecatedTookit/icons/fa/regular/UploadIcon';
import TimesIcon from '@components/deprecatedTookit/icons/fa/regular/TimesIcon';
import LoadingSpinner from '@components/deprecatedTookit/LoadingSpinner';
import Button from '../Button';

import './style.css';

function FileSelectZone(props) {
  const multiple = typeof props.multiple === 'undefined' ? true : !!props.multiple;
  let className = 'r-file-select-zone';
  let fileUploadClassname = 'r-file-select-zone__file-upload';
  const [state, setState] = useState({
    isLoading: false
  });
  const prevIsLoading = useRef(props.isLoading);
  const fileInputRef = useRef(null);

  if (prevIsLoading.current !== props.isLoading) {
    prevIsLoading.current = props.isLoading;
    state.isLoading = props.isLoading;
  }

  if (state.isLoading || props.disabled) {
    className += ' r-file-select-zone--disabled';
    fileUploadClassname += ' r-file-select-zone__file-upload--disabled';
  }

  if (props.className) {
    className += ` ${props.className}`;
  }

  const handleDrop = useCallback((acceptedFiles) => {
    if (props.async) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
    }

    if (props.onFilesAccepted) {
      props.onFilesAccepted(acceptedFiles);
    }
  }, []);

  const handlePaste = useCallback((event) => {
    if (event.clipboardData.files && event.clipboardData.files.length > 0) {
      handleDrop(event.clipboardData.files);
    }
  }, []);

  const handleClose = (event) => {
    event.stopPropagation();

    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    if (props.allowPaste) {
      window.addEventListener('paste', handlePaste);

      return () => {
        window.removeEventListener('paste', handlePaste);
      };
    }
  }, [props.allowPaste]);

  let rightComponent = null;

  if (state.isLoading) {
    rightComponent = <LoadingSpinner className="r-file-select-zone__file-upload-spinner" />;
  } else if (props.onClose) {
    rightComponent = (
      <Button
        className="r-file-select-zone__file-upload-close-btn"
        variant="text"
        icon={<TimesIcon />}
        onClick={handleClose}
      />
    );
  }

  return (
    <Dropzone onDrop={handleDrop} accept={props.accept} multiple={multiple}>
      {({ getRootProps, getInputProps, isDragReject }) => {
        if (isDragReject) {
          if (props.onFilesRejects) {
            props.onFilesRejects();
          }
        }

        return (
          <div className={className}>
            <div {...getRootProps()} className={fileUploadClassname}>
              <input ref={fileInputRef} {...getInputProps()} />
              <UploadIcon className="r-file-select-zone__file-upload-icon" />
              <span className="r-file-select-zone__file-upload-text r-file-select-zone__file-upload-text-link">
                Browse
              </span>
              {props.allowPaste ? <span>,</span> : <span className="r-file-select-zone__file-upload-text">or</span>}
              <span className="r-file-select-zone__file-upload-text">drop</span>
              {props.allowPaste && <span>, or paste </span>}
              <span className="r-file-select-zone__file-upload-text">file</span>
              {rightComponent}
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
}

FileSelectZone.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  async: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  allowPaste: PropTypes.bool,
  accept: PropTypes.string, // Ex. "image/jpeg, image/png"
  onFilesAccepted: PropTypes.func,
  onFilesRejects: PropTypes.func,
  onClose: PropTypes.func
};

export default FileSelectZone;
