import { Env, getEnvFromHostname } from '@drayalliance/utils';

export function getEnvModifier(path: string): { env: string | undefined; modifier: string } {
  const envModifier = {
    [Env.Local]: 'local-env',
    [Env.Test]: 'test-env',
    [Env.Stage]: 'stage-env',
    [Env.Production]: ''
  };

  const env = getEnvFromHostname(path);
  let modifier: Env;

  switch (env.env) {
    case Env.Stage:
      modifier = Env.Stage;
      break;
    case Env.Production:
      modifier = Env.Production;
      break;
    case Env.Test:
      modifier = Env.Test;
      break;
    case Env.Local:
      modifier = Env.Local;
      break;
    default:
      modifier = Env.Production;
      break;
  }

  return { modifier: envModifier[modifier], env: env.env };
}
