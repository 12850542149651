import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './style.css';

function Flyout(props) {
  const baseClassName = 'r-s-Flyout';
  let className = baseClassName;
  const node = useRef();

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (props.direction) {
    className += ` ${baseClassName}--expand-${props.direction}`;
  }

  if (props.nub) {
    className += ` ${baseClassName}--nub`;
  }

  if (props.floating) {
    className += ` ${baseClassName}--floating`;
  }

  const handleDocClick = (event) => {
    if (node.current.contains(event.target)) {
      // inside click
      return;
    }

    // outside click
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleDocClick);

    if (props.onOpen) {
      props.onOpen();
    }

    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleDocClick);
    };
  }, []);

  return (
    <div ref={node} className={className}>
      {props.nub && <div className={`${baseClassName}__nub`} />}
      <div className={`${baseClassName}__content`}>
        {props.children({
          onCloseFlyout: handleClose
        })}
      </div>
    </div>
  );
}

Flyout.propTypes = {
  children: PropTypes.any,
  direction: PropTypes.string,
  className: PropTypes.string,
  nub: PropTypes.bool,
  floating: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};

export default Flyout;
