import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import FileAltIcon from '@components/deprecatedTookit/icons/fa/light/FileAltIcon';
import FilePdfIcon from '@components/deprecatedTookit/icons/fa/light/FilePdfIcon';
import DownloadIcon from '@components/deprecatedTookit/icons/fa/regular/DownloadIcon';
import TrashIcon from '@components/deprecatedTookit/icons/fa/light/TrashAltIcon';
import AddFileIcon from '@components/deprecatedTookit/icons/fa/light/FilePlusIcon';
import CheckIcon from '@components/deprecatedTookit/icons/fa/regular/CheckIcon';
import TimesIcon from '@components/deprecatedTookit/icons/fa/regular/TimesIcon';
import LoadingScreen from '@components/deprecatedTookit/LoadingScreen';
import downloadjs from 'downloadjs';
import mimeTypes from 'mime-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getImageFileSrc } from '../../utils/data-processing/file';
import Button from '../Button';
import FileSelectZone from '../FileSelectZone';
import { createAttachment, deleteAttachment } from '../../state/resources/attachments/actions';
import Image from '../Image/component';
// import testImage from '../../images/test-lg.jpg';

import './style.css';

export default function FileThumbnail(props) {
  // State.
  const [state, setState] = useState({
    fileSrc: null,
    isLoading: true,
    isUploading: false,
    fullScreen: false,
    selectedAction: null
  });
  const { showTimezones } = useFlags();

  const mimeType = props.fileType ? mimeTypes.lookup(props.fileType) : null;
  let altImageText;

  if (props.fileType === 'tiff' || props.fileType === 'tif') {
    altImageText = `.${props.fileType} images are not supported`;
  }

  const clearState = (errors) => {
    setState((prevState) => ({
      ...prevState,
      errors: errors || null,
      isUploading: false
    }));
  };

  const createDialog = () => (
    <div className="r-file-thumbnail__dialog">
      <Image fitToScreen alt={props.displayName} fileType={props.fileType} fileSrc={state.fileSrc} />
    </div>
  );

  const handleDownload = (event) => {
    event.stopPropagation();

    downloadjs(state.fileSrc, props.filename, mimeType);
  };

  const handleImageClick = (event) => {
    event.stopPropagation();

    if (props.onImageClick) {
      props.onImageClick(createDialog());
    }
  };

  const handleNameClick = (event) => {
    event.stopPropagation();

    if (props.onImageClick) {
      props.onNameClick(createDialog());
    }
  };

  const handleFilesAccepted = async (acceptedFiles) => {
    let errors = [];
    setState((prevState) => ({
      ...prevState,
      selectedAction: null
    }));

    try {
      if (!props.attachmentOwnerId) {
        const msg = 'Attachment owner id required.';
        errors.push({
          message: msg
        });

        throw new Error(msg);
      }

      if (!props.attachmentOwnerType) {
        const msg = 'Attachment owner type required.';
        errors.push({
          message: msg
        });

        throw new Error(msg);
      }

      if (!props.attachmentType) {
        const msg = 'Attachment type required.';
        errors.push({
          message: msg
        });

        throw new Error(msg);
      }

      setState((prevState) => ({
        ...prevState,
        errors,
        isUploading: true
      }));

      const formData = new FormData();
      const file = acceptedFiles[0];

      formData.append('file', file);
      formData.append('ownerType', props.attachmentOwnerType);
      formData.append('ownerUuid', props.attachmentOwnerId);
      formData.append('type', props.attachmentType);
      formData.append('name', file.name);

      const asyncResponse = createAttachment(formData);
      const response = await asyncResponse();

      if (props.onAttachmentCreated) {
        props.onAttachmentCreated(response.data);
      }
    } catch (err) {
      errors = err.errors ? errors.concat(err.errors) : errors;
      throw err;
    } finally {
      clearState(errors);
    }
  };

  const handleDelete = async () => {
    let errors = [];

    setState((prevState) => ({
      ...prevState,
      selectedAction: null
    }));

    try {
      if (!props.attachmentId) {
        const msg = 'Attachment id required.';
        errors.push({
          message: msg
        });

        throw new Error(msg);
      }

      const payload = {
        data: [
          {
            type: 'attachment',
            id: props.attachmentId
          }
        ]
      };

      await deleteAttachment(payload)();

      if (props.onAttachmentCreated) {
        props.onAttachmentCreated();
      }
    } catch (err) {
      errors = err.errors ? errors.concat(err.errors) : errors;
      throw err;
    } finally {
      clearState(errors);
    }
  };

  const loadComponent = async () => {
    if (props.onGetImage) {
      try {
        setState((prevState) => ({
          ...prevState,
          isLoading: true
        }));

        let result = null;
        result = await props.onGetImage(props.filePath);
        const fileSrc = await getImageFileSrc(result, mimeType);

        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          fileSrc
        }));
      } catch (err) {
        console.error(err);
        setState((prevState) => ({
          ...prevState,
          isLoading: false
        }));
      }
    }
  };

  useEffect(() => {
    loadComponent();
  }, []);

  const isImage = mimeType ? /^image\//.test(mimeType) : false;
  const isPDF = mimeType ? mimeType === 'application/pdf' : false;
  let className = 'r-file-thumbnail';
  let downloadBtnClassName = 'r-file-thumbnail__footer-action-icon';
  let nameClassName = 'r-file-thumbnail__footer-info-name';
  let imageClassName = 'r-file-thumbnail__image';
  let imageComponent = null;

  if (state.fileSrc && !(isImage || isPDF)) {
    nameClassName += ' r-file-thumbnail__footer-info-name--disabled';
    imageClassName += ' r-file-thumbnail__image--disabled';
  } else if (!state.fileSrc) {
    nameClassName += ' r-file-thumbnail__footer-info-name--disabled';
    imageClassName += ' r-file-thumbnail__image--disabled';
    downloadBtnClassName += ' r-file-thumbnail__footer-action-icon--disabled';
  }

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (props.onGetImage) {
    if (!state.fileSrc && state.isLoading) {
      imageComponent = <LoadingScreen className={imageClassName} />;
    } else if (!state.fileSrc || !(isImage || isPDF)) {
      imageComponent = (
        <div className={imageClassName}>
          <FileAltIcon className="r-file-thumbnail__image-icon" />
        </div>
      );
    } else if (state.fileSrc && isImage) {
      if (altImageText) {
        imageComponent = (
          <object className={imageClassName} data={state.fileSrc}>
            {altImageText}
          </object>
        );
      } else {
        imageComponent = (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <img className={imageClassName} alt={props.displayName} src={state.fileSrc} onClick={handleImageClick} />
        );
      }
    } else if (state.fileSrc && isPDF) {
      imageComponent = (
        <div className={imageClassName} onClick={handleImageClick}>
          <FilePdfIcon className="r-file-thumbnail__image-icon" />
        </div>
      );
    } else {
      imageComponent = <div className={imageClassName} />;
    }
  }

  let extendedActionComponent = <div style={{ height: '30px', backgroundColor: 'red' }} />;

  switch (state.selectedAction) {
    case 'delete': {
      extendedActionComponent = (
        <>
          <Button
            className="r-file-thumbnail__footer-extended-actions-confirmation-button"
            color="disabled"
            label="Cancel"
            icon={<TimesIcon />}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                selectedAction: null
              }))
            }
          />
          <Button
            className="r-file-thumbnail__footer-extended-actions-confirmation-button"
            theme="1"
            label="Confirm"
            icon={<CheckIcon />}
            onClick={handleDelete}
          />
        </>
      );
      break;
    }
    case 'add': {
      extendedActionComponent = <FileSelectZone onFilesAccepted={handleFilesAccepted} />;
      break;
    }
    default:
      break;
  }

  return (
    <div className={className}>
      {imageComponent}
      <div className="r-file-thumbnail__footer">
        <div className="r-file-thumbnail__footer-info">
          <span className={nameClassName} onClick={(isImage || isPDF) && state.fileSrc ? handleNameClick : null}>
            {props.displayName}
          </span>
          <span className="r-file-thumbnail__footer-info-timestamp-one">
            {moment(props.timestampOne)
              .tz(props.timezone || moment.tz.guess())
              .format(`MM/DD/YYYY HH:mm${showTimezones ? ' z' : ''}`)}
          </span>
        </div>
        <div className="r-file-thumbnail__footer-actions">
          {props.attachmentType !== 'messageAttachment' && (
            <TrashIcon
              className={`${downloadBtnClassName}${
                state.selectedAction === 'delete' ? ` ${downloadBtnClassName}--active` : ''
              }`}
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  selectedAction: prevState.selectedAction === 'delete' ? null : 'delete'
                }));
              }}
            />
          )}
          {props.attachmentType !== 'messageAttachment' && (
            <AddFileIcon
              className={`${downloadBtnClassName}${
                state.selectedAction === 'add' ? ` ${downloadBtnClassName}--active` : ''
              }`}
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  selectedAction: prevState.selectedAction === 'add' ? null : 'add'
                }));
              }}
            />
          )}
          <DownloadIcon className={downloadBtnClassName} onClick={state.fileSrc ? handleDownload : null} />
        </div>
        <div className="r-file-thumbnail__footer-extended-actions">{extendedActionComponent}</div>
      </div>
    </div>
  );
}

FileThumbnail.propTypes = {
  className: PropTypes.string,
  displayName: PropTypes.string,
  filename: PropTypes.string,
  filePath: PropTypes.string,
  fileType: PropTypes.string,
  timestampOne: PropTypes.string,
  onGetImage: PropTypes.func,
  onImageClick: PropTypes.func,
  onNameClick: PropTypes.func,
  attachmentId: PropTypes.string,
  attachmentOwnerId: PropTypes.string,
  attachmentOwnerType: PropTypes.string,
  attachmentType: PropTypes.string,
  onAttachmentCreated: PropTypes.func,
  timezone: PropTypes.string
};
