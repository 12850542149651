import React from 'react';
import PropTypes from 'prop-types';

function FloatingActionsBar(props) {
  let className = 'floating-actions-bar';

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    props.children && (
      <>
        {!props.disableBuffer && <div className="floating-actions-bar-buffer" />}
        <div className={className}>{props.children}</div>
      </>
    )
  );
}

FloatingActionsBar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disableBuffer: PropTypes.bool
};

export default FloatingActionsBar;
