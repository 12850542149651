import React, { useContext, useEffect, useState } from 'react';
import Button from '../Button';
import Toaster, { raiseToast, eatToast } from '../Toaster';
import { appErrorStateContext, appErrorActionContext } from '../App/contexts';
import SystemToast from '../SystemToast';
import { createErrorFieldId } from '../../state/utils';
import history from '../../utils/history';
import { clearNotifications } from '../../state/notifications/actions';

function findPos(obj) {
  let curtop = -100;

  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
      // eslint-disable-next-line no-cond-assign
    } while ((obj = obj.offsetParent));

    return curtop;
  }
}

function ErrorToastManager() {
  const errorState = useContext(appErrorStateContext);
  const errorActionDispatch = useContext(appErrorActionContext);
  const [toastIds, setToastIds] = useState({});
  const [staticState] = useState({
    unlisten: null
  });
  const errors = errorState.errors || [];

  const handleClearNotifications = () => {
    Object.keys(toastIds).forEach((errorId) => {
      const toastIdCollection = toastIds[errorId];

      if (toastIdCollection) {
        toastIdCollection.forEach((toastId) => {
          eatToast(toastId);
        });
      }
    });

    setToastIds(() => ({}));

    errorActionDispatch(clearNotifications());
  };

  useEffect(() => {
    errors.forEach((error) => {
      const id = createErrorFieldId(error.id, error.sourceType, error.sourceParam);

      // Avoid creating duplicate toast
      if (!toastIds[id]) {
        const toastId = raiseToast(
          <SystemToast type={SystemToast.Type.ERROR} message={error.detail}>
            <Button
              label="Go"
              theme="1"
              onClick={(event) => {
                event.stopPropagation();

                const el = document.getElementById(id);

                if (el) {
                  window.scroll(0, findPos(el));
                }
              }}
            />
          </SystemToast>,
          {
            autoClose: false,
            closeOnClick: false,
            position: Toaster.Position.BOTTOM_LEFT
          }
        );

        setToastIds((prevState) => {
          let toastIdCollection = prevState[id];

          if (!toastIdCollection) {
            toastIdCollection = [];
          }

          toastIdCollection.push(toastId);

          return {
            ...prevState,
            [id]: toastIdCollection
          };
        });
      }
    });
  }, [errors]);

  useEffect(() => {
    const { errorToRemove } = errorState;
    const toastIdCollection = toastIds[errorToRemove];

    if (errorToRemove && toastIdCollection) {
      toastIdCollection.forEach((toastId) => {
        eatToast(toastId);
      });

      delete toastIds[errorToRemove];
      errorState.errorToRemove = null;
    }
  }, [errorState.errorToRemove]);

  useEffect(() => {
    if (staticState.unlisten) {
      staticState.unlisten();
    }

    staticState.unlisten = history.listen(() => {
      handleClearNotifications();
    });

    return () => {
      staticState.unlisten();
    };
  }, [handleClearNotifications]);

  return null;
}

ErrorToastManager.propTypes = {};

export default ErrorToastManager;
