// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorBoundary {
  display: flex;
  flex-direction: column;
  align-items: center;
}

  .ErrorBoundary__img {
    width: 600px;
    height: 429px;
  }

  .ErrorBoundary__details {
    white-space: pre-wrap;
    width: 100%;
  }

  .ErrorBoundary__content {
    min-width: 800px;
  }
`, "",{"version":3,"sources":["webpack://./app/components/ErrorBoundary/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AAerB;;EAbE;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,qBAAqB;IACrB,WAAW;EACb;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".ErrorBoundary {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  &__img {\n    width: 600px;\n    height: 429px;\n  }\n\n  &__details {\n    white-space: pre-wrap;\n    width: 100%;\n  }\n\n  &__content {\n    min-width: 800px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
