// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-s-Flyout {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #cdcdcd;
  background-color: white;
  top: calc(100% + 2px);
  min-width: 34px;
  min-height: 34px;
  cursor: default;
  align-items: center;
  z-index: 1;
}

  .r-s-Flyout--floating {
    box-shadow: 1px 1px 8px rgb(0 0 0 / 16%);
  }

  .r-s-Flyout--nub {
    top: calc(100% + 9px);
  }

  .r-s-Flyout__nub {
    position: absolute;
    transform: translate3d(0, -50%, 0) rotate(45deg);
    border-top: solid 1px #e5e5ea;
    border-left: solid 1px #e5e5ea;
    width: 10px;
    height: 10px;
    background: #f5f6f7;
  }

  .r-s-Flyout__content {
    width: 100%;
    height: 100%;
  }

  .r-s-Flyout--expand-right {
    align-items: flex-start;
    left: 0;
  }

  .r-s-Flyout--expand-right > .r-flyout__nub {
      transform: translate3d(12px, -50%, 0) rotate(45deg);
    }

  .r-s-Flyout--expand-left {
    align-items: flex-end;
    right: 0;
  }

  .r-s-Flyout--expand-left > .r-flyout__nub {
      transform: translate3d(-12px, -50%, 0) rotate(45deg);
    }
`, "",{"version":3,"sources":["webpack://./app/components/Flyout/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;EACvB,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,UAAU;AA0CZ;;EAxCE;IACE,wCAAwC;EAC1C;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,kBAAkB;IAClB,gDAAgD;IAChD,6BAA6B;IAC7B,8BAA8B;IAC9B,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,uBAAuB;IACvB,OAAO;EAKT;;EAHE;MACE,mDAAmD;IACrD;;EAGF;IACE,qBAAqB;IACrB,QAAQ;EAKV;;EAHE;MACE,oDAAoD;IACtD","sourcesContent":[".r-s-Flyout {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #cdcdcd;\n  background-color: white;\n  top: calc(100% + 2px);\n  min-width: 34px;\n  min-height: 34px;\n  cursor: default;\n  align-items: center;\n  z-index: 1;\n\n  &--floating {\n    box-shadow: 1px 1px 8px rgb(0 0 0 / 16%);\n  }\n\n  &--nub {\n    top: calc(100% + 9px);\n  }\n\n  &__nub {\n    position: absolute;\n    transform: translate3d(0, -50%, 0) rotate(45deg);\n    border-top: solid 1px #e5e5ea;\n    border-left: solid 1px #e5e5ea;\n    width: 10px;\n    height: 10px;\n    background: #f5f6f7;\n  }\n\n  &__content {\n    width: 100%;\n    height: 100%;\n  }\n\n  &--expand-right {\n    align-items: flex-start;\n    left: 0;\n\n    & > .r-flyout__nub {\n      transform: translate3d(12px, -50%, 0) rotate(45deg);\n    }\n  }\n\n  &--expand-left {\n    align-items: flex-end;\n    right: 0;\n\n    & > .r-flyout__nub {\n      transform: translate3d(-12px, -50%, 0) rotate(45deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
