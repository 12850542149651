import React, { useContext, useCallback } from 'react';
import { getAppUrl } from '@drayalliance/utils';
import Component from './component';
import { appActionContext, appStateContext } from '../App/contexts';
import { requestLogout } from '../../state/resources/auth/actions';

export default function Consumer(props) {
  const appDispatch = useContext(appActionContext);
  const appState = useContext(appStateContext);
  const redirectURL = getAppUrl(window.location.hostname, 'accounts', 9002);
  const handleLogout = useCallback(() => {
    appDispatch(requestLogout());
    window.location.assign(redirectURL);
  }, [redirectURL]);

  return <Component {...props} departmentType={appState['auth.selectedDepartment'].type} onLogout={handleLogout} />;
}
